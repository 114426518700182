<template>
   <div class="Fjtest">
     <h3 style="padding: 30px 0;">房角镜检查</h3>
     <UPfile type="FJJ"></UPfile>
     <div class="bz">
       <p>备注：</p>
       <el-input
           type="textarea"
           :autosize="{ minRows: 4, maxRows: 6}"
           placeholder="请输入内容"
           v-model="v1">
       </el-input>
     </div>
   </div>
</template>

<script>
export default {
  name: "Fjtest",
  data() {
    return {
      v1: ''
    }
  },
  components: {
    UPfile: () => import('@/components/CommonComponents/upFile')
  }
}
</script>

<style scoped lang="scss">
.Fjtest {
  padding: 10vh 0;
  width: 90%;
  margin: 0 auto;
  text-align: left;
}
.bz {
  padding-top: 30px;
  p{
    padding-bottom: 10px;
  }
}
</style>
